import classNames from 'classnames';

import {RichText} from 'src/features/shared/components/rich-text';
import { useRichTextRenderer } from 'features/shared/hooks/useRichTextRenderer';
import { Col, Container, Row } from '@features/shared/components/layout';
import { Section } from '@features/shared/components/layout/section';
import { IClubContentPage } from '@features/shared/contentful/types/IClubContentPage';
import { IDealSectionMapped } from '@features/shared/types/IDealSectionMapped';
import { getCheckoutRouteString } from '@utils/get-checkout-route';
import useLang from '@utils/hooks/use-lang';

import { Button } from 'src/features/shared/components/button';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import styles from './index.module.scss';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { FontColor, H3 } from 'src/features/shared/components/typography';

interface IDealProps extends IDealSectionMapped {
  page: IClubContentPage;
  stickToTop?: boolean;
  stickToBottom?: boolean;
}

export function Deal({
  title,
  description,
  image,
  primaryLink,
  primaryLinkLabel,
  isPrimaryLinkCheckoutWithClub,
  secondaryLink,
  page,
  isImageRightPosition,
  backgroundColor,
  stickToTop,
  stickToBottom,
}: IDealProps) {
  const { lang } = useLang();
  const { renderRichText } = useRichTextRenderer(lang);

  return (
    <Section hasNoBottomMargin={stickToBottom} className={styles.section}>
      <Container
        backgroundColor={backgroundColor}
        stickToBottom={stickToBottom}
        stickToTop={stickToTop}
      >
        <Row>
          <Col
            noPadding
            className={styles.col}
            columns="xs:12 lg:8"
            offset="lg:2"
          >
            <div className={styles.container}>
              <div
                className={classNames(styles.card, {
                  [styles.isInverted]: isImageRightPosition,
                })}
              >
                {image && <img className={styles.image} src={image.src} />}
                <div className={styles.container}>
                  <H3 color={FontColor.Neutral01} className={styles.title}>
                    {title}
                  </H3>
                  <RichText>
                    <>{renderRichText(description)}</>
                  </RichText>
                  <div className={styles.buttonsContainer}>
                    {(!!primaryLink || isPrimaryLinkCheckoutWithClub) && (
                      <Button
                        dataTestId="deal-primary-link"
                        onClick={() => {
                          pushGA4Event(GA4_EVENTS.trackEvent, {
                            event_name: 'click_cta_dealsection_primary',
                          });
                        }}
                        href={
                          isPrimaryLinkCheckoutWithClub
                            ? getCheckoutRouteString(
                                page.pgClubId || page.clubId,
                              )
                            : primaryLink?.href
                        }
                      >
                        {primaryLink?.label || primaryLinkLabel}
                      </Button>
                    )}
                    {!!secondaryLink && (
                      <Button
                        variant={ButtonVariant.Inverted}
                        dataTestId="deal-secondary-link"
                        href={secondaryLink.href}
                        onClick={() => {
                          pushGA4Event(GA4_EVENTS.trackEvent, {
                            event_name: 'click_cta_dealsection_secondary',
                          });
                        }}
                      >
                        {secondaryLink.label}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}
