import { CHECKOUT_URL } from 'src/features/pg-funnel/utils/constants'

export default function getCheckoutRoute (clubId: string | number) {
  return {
    href: {
      pathname: '/funnel/default',
      query: { clubId }
    }
  }
}

export function getCheckoutRouteString (clubId: string | number) {
  return `/${CHECKOUT_URL}/default?clubId=${clubId}`
}

